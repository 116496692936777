<template>
  <div class="longform-section">
    <div class="container">
      <div class="longform-hero">
        <figure class="media-block">
          <img
            :src="content.image"
            alt="cover image"
          >
        </figure>
        <div class="text-wrapper">
          <div class="title-block">
            <h2 v-html="content.title" />
            <p
              class="lead"
              v-html="content.text"
            />
          </div>
        </div>
      </div>
      <div class="longform-body">
        <ImageTextPair
          v-for="(listicle, index) in content.imageTextPair"
          :key="`item-${index}`"
          :listicle="listicle"
          :class="`image-text-pair index-${index}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageTextPair from '../components/ImageTextPair.vue'

export default {
  components: {
    ImageTextPair,
  },
  computed: {
    content() {
      return this.$store.getters.QUIZDATA.listicle
    },
  },
}
</script>
