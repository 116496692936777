<template>
  <div v-if="listiquiz">
    <ThesisForm>
      <template #onePageIntro>
        <Listicle />
      </template>
      <template #onePageTop>
        <CustomIntro />
      </template>
      <template #headerSlot>
        <CustomHeader />
      </template>
      <template #footerSlot>
        <CustomFooter />
      </template>
    </ThesisForm>
  </div>
  <div v-else>
    <ThesisForm />
  </div>
</template>

<script>
import CustomHeader from './sections/CustomHeader.vue'
import CustomFooter from './sections/CustomFooter.vue'
import CustomIntro from './sections/CustomIntro.vue'
import Listicle from './sections/Listicle.vue'

export default {
  components: {
    CustomHeader,
    CustomFooter,
    CustomIntro,
    Listicle,
  },
  computed: {
    listiquiz() {
      return this.$store.getters.QUIZDATA.listiquiz
    },
    quizResults() {
      return this.$store.getters.RESULTS
    },
    content() {
      return this.quizResults.filter(result => result.content[0] !== undefined || null)
    },
    check() {
      return this.content.length > this.$quizData.sequences.length - 1
    },
  },
  watch: {
    check() {
      this.$store.dispatch('ONE_PAGE_VALID')
      this.$store.dispatch('QUIZ_COMPLETED')
    },
  },
  created() {
    this.$store.dispatch('INITIALIZE_STORE', this.$quizData)
    this.$store.dispatch('/results/INITIALIZE_RESULTS', this.$quizData.sequences)
  },
}
</script>
