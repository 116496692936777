<template>
  <section class="one-page-hero">
    <div class="quiz-hero-content">
      <div class="text-wrapper">
        <hgroup class="title-block">
          <h3 v-html="content.title" />
          <p
            class="lead"
            v-html="content.text"
          />
        </hgroup>
        <div class="cta-block">
          <button
            class="btn"
            @click="quizStarted"
          >
            {{ content.cta_text }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    content() {
      return this.$store.getters.QUIZDATA.intro
    },
  },
  methods: {
    quizStarted() {
      document.querySelector('#onepage').style.display = 'block'
      this.$store.dispatch('QUIZ_STARTED')
      this.$scrollTo('#onepage')
    },
  },
}
</script>
