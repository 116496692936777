/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
export default {
  MUTATE_INITIALIZE_STORE: (state, payload) => state.quizData = { ...state.quizData, ...payload, },
  MUTATE_ADVANCE_QUIZ: (state, payload) => {
    state.advanceQuiz.currentStep++
    if (payload) {
      state.advanceQuiz.goToBranch = payload
    } else {
      state.advanceQuiz.goToBranch = null
    }
  },
  MUTATE_SET_FULLREVEALED: state => state.fullRevealed = !state.fullRevealed,
  MUTATE_QUIZ_STARTED: state => state.quizStarted = true,
  MUTATE_QUIZ_COMPLETED: state => state.quizCompleted = true,
  MUTATE_SET_LOADED: state => state.loaded = true,
  MUTATE_ONE_PAGE_VALID: state => state.onePageValid = true,
}
