/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */

const state = {
  results: [],
  correctAnswers: 0,
}

const getters = {
  RESULTS: state => state.results,
  CORRECT_ANSWERS: state => state.correctAnswers,
}

const mutations = {
  // eslint-disable-next-line array-bracket-spacing
  '/results/MUTATE_INITIALIZE_RESULTS': (state, payload) => {
    if ((payload.length > 0) && (state.results.length < 1)) {
      state.results = payload.map((item, index) => state.results[index] = {
        label: null,
        questionIndex: index,
        content: [],
        correct: false,
        index: null,
      })
    }
    return state.results
  },
  '/results/MUTATE_UPDATE_CONTENT_RESULTS': (state, payload) => {
    const newState = JSON.parse(JSON.stringify(state))
    const result = newState.results.find(result => result.questionIndex === payload.questionIndex)
    if (result.content[0]) {
      const updatedContent = result.content.find(result => result.index === payload.index)
      const resultIndex = result.content.findIndex(el => el === updatedContent)
      const currentContent = newState.results[payload.questionIndex].content
      currentContent.splice(resultIndex, 1)
      state.results = [...newState.results, ]
    }
    return state.results
  },
  '/results/MUTATE_UPDATE_RESULTS': (state, payload) => {
    const newState = JSON.parse(JSON.stringify(state))
    const resultIdToUpdate = payload.questionIndex
    const result = newState.results.find(result => result.questionIndex === resultIdToUpdate)
    result.label = payload.label
    result.correct = payload.correct
    result.index = payload.index
    if (payload.questionType === 'multi-select') {
      const contentMulti = [...payload.content, ]
      const uniqueContent = new Set(contentMulti)
      result.content = [...uniqueContent, ]
    } else {
      result.content = payload.content
    }
    state.results = [...newState.results, ]
    return state.results
  },
  '/results/MUTATE_SUBMIT_ONE_PAGE': (state, payload) => state.results = [...payload, ],
  '/results/MUTATE_INCREMENT_CORRECT_ANSWERS_COUNT': state => state.correctAnswers++,
}

const actions = {
  '/results/INITIALIZE_RESULTS': ({ commit, }, payload) => {
    commit('/results/MUTATE_INITIALIZE_RESULTS', payload)
  },
  '/results/UPDATE_RESULTS': ({ commit, }, payload) => {
    commit('/results/MUTATE_UPDATE_RESULTS', payload)
  },
  '/results/UPDATE_CONTENT_RESULTS': ({ commit, }, payload) => {
    commit('/results/MUTATE_UPDATE_CONTENT_RESULTS', payload)
  },
  '/results/SUBMIT_ONE_PAGE': ({ commit, }, payload) => {
    commit('/results/MUTATE_SUBMIT_ONE_PAGE', payload)
  },
  '/results/INCREMENT_CORRECT_ANSWERS_COUNT': ({ commit, }) => {
    commit('/results/MUTATE_INCREMENT_CORRECT_ANSWERS_COUNT')
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}