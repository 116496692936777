<template>
  <div>
    <h2
      class="mobile-title"
      v-html="listicle.title"
    />
    <figure
      v-if="listicle.image"
      class="media-block image-block"
    >
      <img
        :src="listicle.image"
        alt="listicle image"
      >
    </figure>
    <div class="text-block">
      <hgroup class="title-block">
        <h2
          class="desktop-title"
          v-html="listicle.title"
        />
        <p
          class="lead"
          v-html="listicle.text"
        />
      </hgroup>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    listicle: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
