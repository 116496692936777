/* eslint-disable no-param-reassign */
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import results from './modules/results'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    quizData: {},
    advanceQuiz: {
      currentStep: 0,
      goToBranch: null,
    },
    onePageValid: false,
    quizStarted: false,
    quizCompleted: false,
    loaded: false,
  },
  getters,
  mutations,
  actions,
  modules: {
    results,
  },
})

export default store
