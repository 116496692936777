<template>
  <header class="quiz-header">
    <div class="header-container">
      <a
        v-if="quizData.logo_link"
        :href="quizData.logo_link"
      ><img :src="quizData.logo_path">
      </a>
      <img
        v-else
        :src="quizData.logo_path"
      >
      <div
        v-if="quizData.cta_text"
        class="cta-block"
      >
        <a
          class="btn"
          :href="quizData.cta_link"
        >{{ quizData.cta_text }}</a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  computed: {
    quizData() {
      return this.$store.getters.QUIZDATA
    },
  },
}
</script>
