<template>
  <footer
    v-if="content"
    class="tf-footer"
  >
    <div class="footer-meta">
      <figure v-if="content.logo_path">
        <img
          class="footer-logo"
          :src="content.logo_path"
          alt="logo"
        >
      </figure>
      <p class="footer-copyright">
        {{ content.copyright }}
      </p>

      <nav
        v-if="content.links"
        class="footer-links"
      >
        <ul>
          <li
            v-for="(link, index) in content.links"
            :key="index"
          >
            <a :href="link.url">{{ link.text }}</a>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    content() {
      return this.$store.getters.QUIZDATA.footer
    },
  },
}
</script>
